export default {
  methods : {
    // eslint-disable-next-line no-undef
    checkProfanity : _.debounce(function(obj, str) {
      this.$http.post('/api/profanity', {
        words : str,
      }).then(res => {
        this[obj] = !res.data;
      }).catch(() => {
        this[obj] = false;
      })
    }, 500),
    sanitizeWhitespace(key, object = this) {
      let str = object[key];
      if (str) {
        str = str.replace( /\s\s+/g, ' ' );
        str = str.trim();
        object[key] = str;
      }
    },
  },
};