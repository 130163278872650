<template>
  <div class="edit-form">
    <h4>Edit Profile</h4>

    <b-form @submit.prevent="updateProfile">
      <div v-if="!profile.user_profiles_id">
        <b-input-group prepend="Nickname">
          <b-form-input
            id="nickname"
            type="text"
            aria-describedby="formNickname"
            autocomplete="off"
            placeholder="Nickname"
            :state="!$v.form.nickname.$error"
            v-model="form.nickname"
            @input="$v.form.nickname.$touch()"
            @focusout="sanitizeWhitespace('nickname', form)"
          ></b-form-input>
          <b-form-invalid-feedback id="formNickName">
            <span v-if="!$v.form.nickname.required">
              Nickname is required.
            </span>
            <span v-if="!$v.form.nickname.safeNickname">
              Nickname not Allowed.
            </span>
            <span
              v-if="!$v.form.nickname.uniqueNickname &&
                $v.form.nickname.required">
              Nickname is already used.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-input-group prepend="First Name">
          <b-form-input
            id="firstName"
            type="text"
            aria-describedby="formFirstName"
            autocomplete="off"
            placeholder="First Name"
            :state="!$v.form.firstname.$error"
            v-model="form.firstname"
            @input="$v.form.firstname.$touch()"
            @focusout="sanitizeWhitespace('firstName', form)"
          ></b-form-input>
          <b-form-invalid-feedback id="formFirstName">
            <span v-if="!$v.form.firstname.required">
              First Name is required.
            </span>
            <span v-if="!$v.form.firstname.name && $v.form.firstname.required">
              Contains invalid characters.
            </span>
            <span v-if="!$v.form.firstname.safeFirstName">
              First Name not allowed.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-input-group prepend="Last Name">
          <b-form-input
            id="lastName"
            type="text"
            aria-describedby="formLastName"
            autocomplete="off"
            placeholder="Last Name"
            :state="!$v.form.lastname.$error"
            v-model="form.lastname"
            @input="$v.form.lastname.$touch()"
            @focusout="sanitizeWhitespace('lastName', form)">
          </b-form-input>
          <b-form-invalid-feedback id="formLastName">
            <span v-if="!$v.form.lastname.required">
              Last Name is required.
            </span>
            <span v-if="!$v.form.lastname.name && $v.form.lastname.required">
              Contains invalid characters.
            </span>
            <span v-if="!$v.form.lastname.safeLastName">
              Last Name not allowed.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>
      </div>

      <b-input-group prepend="Bio" class="mb-0">
        <b-form-textarea
          id="textarea-default"
          v-model="form.bio"
          aria-describedby="formBio"
          max-rows="4"
          rows="3"
          placeholder="Bio"
          :state="!$v.form.bio.$error"
          @input="$v.form.bio.$touch()"
          @focusout="sanitizeWhitespace('bio', form)"
        ></b-form-textarea>
        <b-form-invalid-feedback id="formBio">
          <span v-if="!$v.form.bio.safeBio">
            Bio not allowed.
          </span>
        </b-form-invalid-feedback>
      </b-input-group>
      <p class="small text-chars mb-0">
        {{ bioRemainingChars }} remaining characters
      </p>

      <div class="change-pass-wrapper">
        <b-button
          class="btn-h3-orange"
          v-show="!!!user.is_social && !profile.user_profiles_id"
          type="button"
          @click="openChangePass">
          Change Password
        </b-button>
      </div>

      <div class="text-center button-container mx-auto">
        <b-row>
          <b-col cols="6">
            <b-button
              class="btn-h3-orange"
              type="submit"
              :disabled="$v.form.$invalid || isFormEdited === false ||
                $v.$invalid"
              block>
              Update
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              class="btn-h3-orange"
              type="button"
              @click="$emit('cancel-edit')"
              block>
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import { required, helpers } from 'vuelidate/lib/validators';
  import { mapGetters } from 'vuex';
  import ProfanityMixin from '../../mixins/ProfanityMixin';

  const name = helpers.regex(name, /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/);
  const ChangePassword = () => import('./ChangePasswordModal');

  export default {
    mixins : [
      ProfanityMixin,
    ],
    data() {
      return {
        loading : false,
        form    : {
          nickname  : null,
          firstname : null,
          lastname  : null,
          bio       : null,
        },
        safeNickname   : true,
        safeFirstName  : true,
        safeLastName   : true,
        safeBio        : true,
        isFormEdited   : false,
        uniqueNickname : true,

        bioMaxChars       : 60,
        bioRemainingChars : 60,
      }
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
    },
    computed : {
      ...mapGetters({
        user    : 'user/user',
        profile : 'profile/info',
      }),
    },
    watch : {
      'form.nickname'(str) {
        this.safeNickname = true;
        this.uniqueNickname = true;
        if (str) {
          this.checkProfanity('safeNickname', str);
          this.checkNickname();
        }
      },
      'form.firstname'(str) {
        if (str)
          this.checkProfanity('safeFirstName', str);
        else
          this.safeFirstName = true;
      },
      'form.lastname'(str) {
        if (str)
          this.checkProfanity('safeLastName', str);
        else
          this.safeLastName = true;
      },
      'form.bio' : function(str) {
        this.bioRemainingChars = this.bioMaxChars;
        if (str) {
          this.checkProfanity('safeBio', str);
          this.bioRemainingChars = this.bioMaxChars - this.form.bio.length;
        } else
          this.safeBio = true;
      },
    },
    methods : {

      /**
       * Update Profile in the database
       */
      updateProfile() {
        this.loading = true;
        const fields = {};
        if (this.profile.user_profiles_id) {
          fields.bio = this.form.bio;
          this.$http.put('api/user/profile', {
            userProfileId : this.profile.user_profiles_id,
            updateFields  : JSON.stringify(fields),
          }).then(() => {
            this.isFormEdited = false;
            this.$store.dispatch(
              'profile/getInfo',
              this.profile.user_profiles_id);
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success!',
              text  : 'Your profile has been updated!',
            });
            this.$emit('success-edit');
          }).catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong.',
            });
          }).finally(() => {
            this.loading = false;
          });
        } else {
          if (this.form.nickname !== this.user.nickname)
            fields.nickname = this.form.nickname;
          if (this.form.firstname !== this.user.firstname)
            fields.firstname = this.form.firstname;
          if (this.form.lastname !== this.user.lastname)
            fields.lastname = this.form.lastname;
          if (this.form.bio !== this.user.bio)
            fields.bio = this.form.bio;

          this.$http.put('api/user', {
            userId       : this.user.user_id,
            updateFields : fields,
          }).then(() => {
            this.isFormEdited = false;
            this.$store.dispatch('user/getUpdatedUser');
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success!',
              text  : 'Your profile has been updated!',
            });
            this.$emit('success-edit');
          }).catch(err => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : (err.response.data.msg) ? err.response.data.msg :
                'Oops! Something went wrong.',
            });
          }).finally(() => {
            this.loading = false;
          });
        }
        
      },

      /**
       * Additional Form Validation
       * @param newVal
       */
      formUpdated(newVal) {
        const u = this.user;
        if (newVal.firstname != u.firstname ||
          newVal.lastname != u.lastname ||
          newVal.nickname != u.nickname ||
          newVal.bio != u.bio)
          this.isFormEdited = true;
        else
          this.isFormEdited = false;
      },

      /**
       * Change Password Modal
       */
      openChangePass() {
        this.$modal.show(ChangePassword, {}, {
          height   : 'auto',
          adaptive : true,
        }, {
          'close-modal' : (event) => {
            this.modal.hide();
          },
        });
      },

      /**
       * Check if Nickname exists
       */
      checkNickname : _.debounce(function() {
        this.$http.get('api/user/nickname', {
          params : {
            nickname : this.form.nickname,
          },
        }).then(response => {
          this.uniqueNickname = (response.data) ? false : true;
          if (this.form.nickname === this.user.nickname)
            this.uniqueNickname = true;
        });
      }, 500),
    },
    created() {
      this.$watch('form', this.formUpdated, {
        deep : true,
      });
    },
    mounted() {
      if (this.profile.user_profiles_id && this.profile.bio !== undefined)
        this.form.bio = this.profile.bio;
      
      if (!this.profile.user_profiles_id) {
        this.form.nickname = this.user.nickname;
        this.form.firstname = this.user.firstname;
        this.form.lastname = this.user.lastname;
        this.form.bio = this.user.bio;
      }
    },
    validations() {
      return {
        form : {
          nickname : (this.profile.user_profiles_id) ? {} : {
            required,
            safeNickname() {
              return this.safeNickname;
            },
            uniqueNickname() {
              return this.uniqueNickname;
            },
          },
          firstname : (this.profile.user_profiles_id) ? {} : {
            required,
            name,
            safeFirstName() {
              return this.safeFirstName;
            },
          },
          lastname : (this.profile.user_profiles_id) ? {} : {
            required,
            name,
            safeLastName() {
              return this.safeLastName;
            },
          },
          bio : {
            safeBio() {
              return this.safeBio;
            },
            allowedChars() {
              return (this.form.bio) ? this.form.bio.length <= 60 : true;
            },
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .edit-form {
    margin: 1.5rem 0;
    padding: 2rem;
    background-color:  rgba(73, 149, 24, 0.5);
    border: 1px dashed #068bcd;
    border-radius: 0.5rem;

    p {
      color: $primary-color;
    }

    p.small {
      font-size: 1em !important;
    }

    .input-group {
      margin-bottom: 1rem;
    }

    .input-group-text {
      width: 6.5rem;
      background-color: #e7932d !important;
      color: #fff;
      font-family: "WickedMouse";
      font-size: 1em;
      -webkit-text-stroke: 1px #a1409d !important;
    }

    .invalid-feedback {
      // padding-left: 6.5rem;
      color: #fff;
    }

    .form-control {
      height: 2.9rem;
    }

    .button-container {
      margin-top: 1rem;
      width: 100%;
    }

    .text-chars {
      color: #fff;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .edit-form {
      padding: 1.25rem;
    }
    .input-group-text {
      width: 8rem !important;
      font-size: 0.85em !important;
    }
    .big {
      font-size: 1.5em !important;
    }
    .text-chars {
      text-align: center;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .input-group-text {
      width: 12rem !important;
      font-size: 1.4rem;
    }
    .text-chars {
      text-align: right;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .input-group-text {
      width: 15rem !important;
      font-size: 1.4rem;
    }
    .invalid-feedback {
      padding-left: 15.5rem;
      color: #fff;
    }
    .text-chars {
      text-align: right;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .text-chars {
      text-align: right;
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .button-container {
      width: 50% !important;
    }
  }

  @media only screen and (min-width: $special) {
    
  }

  @media only screen and (min-width: $xl2-min) {
    .invalid-feedback {
      padding-left: 15.5rem;
      color: #fff;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
   
  }
  
</style>